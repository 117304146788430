<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Add Brand
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container> 
                <form>
                    <v-select
                            v-if="provShow"
                            v-model="editedData.provinceId"
                            :items="province"
                              dense
                                                outlined
                            label="Select Province"
                            item-value="id"
                            item-text="value"
                    ></v-select>
                    <v-select
                            v-if="divShow"
                            v-model="editedData.divisionId"
                            :items="division"
                              dense
                                                outlined
                            label="Select Business Sector"
                            item-value="id"
                            item-text="value"
                    ></v-select>
                    <v-text-field
                            v-model="editedData.branchName"
                            :error-messages="branchNameErrors"
                            label="Brand Name"
                            required
                                  dense
                                                outlined
                            @input="$v.editedData.branchName.$touch()"
                            @blur="$v.editedData.branchName.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.branchCode"
                            :error-messages="branchCodeErrors"
                            label="Brand Code"
                            required
                                  dense
                                                outlined
                            @input="$v.editedData.branchCode.$touch()"
                            @blur="$v.editedData.branchCode.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.branchAddress"
                            :error-messages="branchAddressErrors"
                            label="Brand Address"
                            required
                                  dense
                                                outlined
                            @input="$v.editedData.branchAddress.$touch()"
                            @blur="$v.editedData.branchAddress.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.branchPhoneNumber"
                            :error-messages="branchPhoneNumberErrors"
                            label="Brand Phone Number"
                            required
                                  dense
                                                outlined
                            @input="$v.editedData.branchPhoneNumber.$touch()"
                            @blur="$v.editedData.branchPhoneNumber.$touch()"
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createBranchForm",
        props: ["success"],
        watch: {
            dialog(val) {
                val || this.close();
            },
            created: {
                handler: function (val) {
                    if (this.created) {
                        this.created = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            },
            "editedData.provinceId": {
                handler: async function (val) {
                    if (val) {
                        this.division = [];
                        const divisionBranch = await axios.get("Division/DDLDivisionList", {
                            params: {ProvinceID: val}
                        });
                        this.division = divisionBranch.data;
                        this.editedData.divisionId = val;
                    }
                },
                deep: true
            }
        },
        computed: {
            branchAddressErrors() {
                const errors = [];
                if (!this.$v.editedData.branchAddress.$dirty) return errors;
                !this.$v.editedData.branchAddress.required &&
                errors.push("Brand Address is required");
                return errors;
            },
            branchNameErrors() {
                const errors = [];
                if (!this.$v.editedData.branchName.$dirty) return errors;
                !this.$v.editedData.branchName.required &&
                errors.push("Brand name is required.");
                return errors;
            },
            branchCodeErrors() {
                const errors = [];
                if (!this.$v.editedData.branchCode.$dirty) return errors;
                !this.$v.editedData.branchCode.required &&
                errors.push("Brand Code is required.");
                return errors;
            },
            branchPhoneNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.branchPhoneNumber.$dirty) return errors;
                !this.$v.editedData.branchPhoneNumber.required &&
                errors.push("Brand Phone Number is required.");
                return errors;
            }
        },
        data() {
            return {
                created: false,
                provShow: false,
                divShow: false,
                province: [],
                division: [],
                editedData: {
                    divisionId: 0,
                    provinceId: 0,
                    branchAddress: "",
                    branchCode: "",
                    branchName: "",
                    branchPhoneNumber: ""
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        created() {
            this.formData();
        },
        validations: {
            editedData: {
                branchAddress: {required},
                branchPhoneNumber: {required},
                branchCode: {required},
                branchName: {required}
            }
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                let permissionData = JSON.parse(localStorage.getItem("tokenData"));
                if (permissionData.role == "Ministry") {
                    this.provShow = true;
                    this.divShow = true;
                    const provinceRequest = await axios.get("Province/DDLProvinceList");
                    this.province = provinceRequest.data;
                } else if (permissionData.role == "Province") {
                    this.divShow = true;
                    this.editedData.provinceId = permissionData.ProvinceID;
                } else if (permissionData.role == "Division") {
                    this.editedData.provinceId = permissionData.ProvinceID;
                    this.editedData.divisionId = permissionData.DivisionID;
                }
            },
            destroyed() {
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        ProvinceID: parseInt(this.editedData.divisionId),
                        DivisionID: parseInt(this.editedData.divisionId),
                        BranchName: this.editedData.branchName,
                        BranchCode: this.editedData.branchCode,
                        BranchAddress: this.editedData.branchAddress,
                        BranchPhoneNumber: this.editedData.branchPhoneNumber
                    };
                    axios.post("Branch/CreateBranch", param).then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Package added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.branchName = "";
                this.editedData.branchAddress = "";
                this.editedData.branchCode = "";
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            }
        }
    };
</script>

<style scoped>
</style>