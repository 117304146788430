<template>
    <v-card>
        <v-toolbar dark color="primary">
            <v-toolbar-title>{{$t('document_viewer')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-container>
            <v-row align="center" justify="center" class="pa-4">
                <v-col cols="4">
                    <v-col> 
                        <v-img
                                :src="`${baseUrl}/Images/Branch/${documents.branchID}/${documents.registerDocument}`"
                                :lazy-src="`${baseUrl}/Images/Branch/${documents.branchID}/${documents.registerDocument}`"
                                aspect-ratio="1"
                                class="grey lighten-2 doc-image"
                                @click="changeImage(`${baseUrl}/Images/Branch/${documents.branchID}/${documents.registerDocument}`,'Register Document')"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <label for=""> {{$t('register_document')}} </label>
                    </v-col>
                    <v-col>
                        <v-img
                                :src="`${baseUrl}/Images/Branch/${documents.branchID}/${documents.vatDocument}`"
                                :lazy-src="`${baseUrl}/Images/Branch/${documents.branchID}/${documents.vatDocument}`"
                                aspect-ratio="1"
                                class="grey lighten-2 doc-image"
                                @click="changeImage(`${baseUrl}/Images/Branch/${documents.branchID}/${documents.vatDocument}`,'Vat Document')"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <label for=""> {{$t('vat_document')}} </label>
                    </v-col>
                    <v-col>
                        <v-img
                                :src="`${baseUrl}/Images/Branch/${documents.branchID}/${documents.taxClearanceDocument}`"
                                :lazy-src="`${baseUrl}/Images/Branch/${documents.branchID}/${documents.taxClearanceDocument}`"
                                aspect-ratio="1"
                                class="grey lighten-2 doc-image"
                                @click="changeImage(`${baseUrl}/Images/Branch/${documents.branchID}/${documents.taxClearanceDocument}`,'Tax Clearance Document')"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <label for=""> {{$t('tax_clearance_document')}}t </label>

                    </v-col>
                </v-col>
                <v-col cols="8">
                    <v-img
                            :src="`${previewImage}`"
                            :lazy-src="`${previewImage}`"
                            aspect-ratio="1"
                            class="grey lighten-2 featured-image"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                            >
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                    <label for=""> {{docName}} </label>

                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    export default {
        name: "createBranchForm",
        props: ["documents"],
        computed: {},
        data() {
            return {
                previewImage: "https://image.freepik.com/free-photo/futuristic-technology-screen-interface_23-2148468690.jpg",
                baseUrl: window.location.origin
            };
        },
        created() {
            this.loadData();
        },
        methods: {
            changeImage(data, name) {
                this.previewImage = data
                this.docName = name
            },
            async loadData() {
                this.previewImage = this.baseUrl + "/Images/Branch/" + this.documents.branchID + "/" + this.documents.taxClearanceDocument
                this.docName = this.$t('tax_clearance_document')
            },
            destroyed() {
            },
            close() {
                console.log("test")
                setTimeout(() => {
                    this.$emit("formResponse");
                }, 300);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .doc-image {
        max-height: 10em;
        max-width: 10em;
    }

    .featured-image {
        max-height: 40em;
        max-width: 35em;
    }
</style>