<template>
    <v-content>
        <v-dialog v-model="dialogCreate" max-width="500px">
            <template v-slot:activator="{ on }">
                <!-- <v-btn color="primary" dark class="mb-2 float-right" v-on="on" :success="success">Add Branch</v-btn> -->
            </template>
            <createBranchForm :success="success" @formResponse="onResponse"/>
        </v-dialog>
        <v-snackbar v-model="snackbar.snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar.snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="dialogEdit" max-width="500px">
            <editBranchForm
                    v-if="dialogEdit"
                    :branchId="filterEditedData.branchId"
                    :success="success"
                    @formResponse="onResponse"
            />
        </v-dialog>

        <v-dialog v-model="dialogDocument" fullscreen hide-overlay max-width="500px">
            <DocumentViewer
                    v-if="dialogDocument"
                    :documents="documents"
                    @formResponse="onDocumentClose"
            />
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <v-badge
                bordered
                color="rgb(157, 25, 25)"
                :content="countBranch.InActive"
                v-if="countBranch.InActive"
                overlap
        >
            <v-btn class="white--text" color="rgb(157, 25, 25)" depressed @click="pendingMethods">{{$t('table.pending')}}</v-btn>
        </v-badge>

        <v-expansion-panels hover v-if="pendingDataFlag && countBranch.InActive" v-model="panel" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header>{{$t('form.pending_data')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <vue-good-table :columns="pendingDataHeader" :rows="pendingData" max-height="200px">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'action'">
                                    <v-btn v-if="!isloadingFromServer" class="px-2 mx-2" color="success" @click="activeMethod(props.row,true)">{{$t('table.approved')}}</v-btn>
                                    <v-btn v-if="!isloadingFromServer" color="px-2 mx-2 error" @click="activeMethod(props.row,false)">{{$t('table.declined')}}</v-btn>
                                    <v-btn color="px-2 mx-2 primary" @click="showDocument(props.row)">{{$t('table.documents')}}</v-btn>
                                    <span v-if="isloadingFromServer">please wait... </span>
                                </span>
                            </template>
                        </vue-good-table>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels hover>
            <v-expansion-panel>
                <v-expansion-panel-header>{{$t('table.filter')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <v-row align="center">
                            <v-col class="float-right" cols="12" sm="4" v-if="isSuperAdmin||isMinistry||isAssociation|| isParentDivision">
                                <v-autocomplete v-model="serverParams.ProvinceID"
                                                :items="ProvinceList"
                                                :label="$t('form.select_province')"
                                                clearable
                                                dense
                                                outlined
                                                @change="getDistrictList(serverParams.ProvinceID);getDivisionList(serverParams.ProvinceID);"
                                                item-text="value"
                                                item-value="id"></v-autocomplete>
                            </v-col>
                            <v-col class="float-right" cols="12" sm="4" v-if="isSuperAdmin||isMinistry||isAssociation|| isParentDivision">
                                <v-autocomplete v-model="serverParams.DistrictName"
                                                :items="DistrictList"
                                                :label="$t('form.select_district')"
                                                clearable
                                                dense
                                                outlined
                                                item-text="value"
                                                item-value="id"></v-autocomplete>
                            </v-col>
                            <v-col class="float-right" cols="12" sm="4" v-if="isSuperAdmin||isMinistry||isProvince">
                                <v-autocomplete v-model="serverParams.DivisionID"
                                                :items="DivisionList"
                                                :label="$t('table.business_sector')"
                                                clearable
                                                dense
                                                outlined
                                                item-text="value"
                                                item-value="id"></v-autocomplete>
                            </v-col>
                            <v-col class="float-right" cols="12" sm="4">
                                <v-text-field clearable
                                              dense
                                                outlined
                                              v-model="serverParams.BranchName"
                                              :label="$t('form.branch_name')"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center">
                            <v-btn color="primary" dark @click="searchInput" class="mr-1">
                                <i class="material-icons">search</i>{{$t('search')}}
                            </v-btn>
                            <v-btn color="rgb(157, 25, 25)" dark @click="clearInput">
                                <i class="material-icons">clear</i>{{$t('clear')}}
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :totalRows="totalRecords"
                :isLoading.sync="isLoading"
                :pagination-options="{ enabled: true,  perPageDropdown: [50, 100,200]}"
                :rows="rows"
                :columns="columns"
        >
            <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <v-icon small class="mr-2" @click="editItem(props.row)">edit</v-icon>
          <v-icon small @click="deleteItem(props.row)">delete</v-icon>
             <v-chip
                     small
                     class="ml-2"
                     color="green"
                     text-color="white"
                     @click="loadServices(props.row)"
             >
             {{$t('table.services')}}
            </v-chip>
             <v-chip
                     small
                     class="ml-2 darken-2"
                     color="rgb(157, 25, 25)"
                     text-color="white"
                     @click="loadDetails(props.row)"
             >
             {{$t('table.details')}}
            </v-chip>
        </span>
            </template>
        </vue-good-table>
        <v-dialog
                v-model="snackbar.modal"
                transition="dialog-bottom-transition"
                max-width="790"
                scrollable
                persistent
                hide-overlay
        >

            <BranchServices @dialogueClose="dialogueClose"
                            :divisionName="branchData.divisionName"
                            :branchID="branchData.branchID"
                            v-if="snackbar.branchServices"
            ></BranchServices>
            <BranchDetails @dialogueClose="dialogueClose"
                           :divisionName="branchData.divisionName"
                           :branchID="branchData.branchID"
                           v-if="snackbar.branchDetails"
            ></BranchDetails>
        </v-dialog>
    </v-content>
</template>

<script>
    import axios from "axios";
    import createBranchForm from "./create";
    import editBranchForm from "./edit";
    import deleteListData from "../../components/deleteModal";
    import DocumentViewer from "./DocumentViewer";
    import BranchServices from "../../components/servicesProvinces/BranchServices";
    import BranchDetails from "../../components/servicesProvinces/BranchDetails";
    import {mapGetters} from "vuex";

    export default {
        name: "List",
        computed: {
            ...mapGetters(['getSystemUserData']),
            isMinistry() {
                return this.getSystemUserData.role === 'Ministry'
            },
            isSuperAdmin() {
                return this.getSystemUserData.role === 'SuperAdmin'
            },
            isAssociation() {
                return this.getSystemUserData.role === 'Association'
            },
            isParentDivision() {
                return this.getSystemUserData.role === 'ParentDivision'
            },
            isProvince() {
                return this.getSystemUserData.role === 'Province'
            },
            isDivision() {
                return this.getSystemUserData.role === 'Division'
            },
            columns(){
                return [
                    {
                        label: this.$t('table.province'),
                        field: "provinceName"
                    },
                    {
                        label: this.$t('table.business_sector'),
                        field: "divisionName"
                    },
                    {
                        label: this.$t('table.brand_name'),
                        field: "branchName"
                    },
                    {
                        label: this.$t('table.brand_code'),
                        field: "branchCode"
                    },
                    {
                        label: this.$t('table.brand_address'),
                        field: "branchAddress"
                    },
                    {
                        label:this.$t('table.active'),
                        field: "isActive"
                    },
                    {
                        label: this.$t('table.actions'),
                        field: "actions",
                        sortable: false
                    }
                ]
            }
        },
        watch: {
            "filterEditedData.provinceId": {
                handler: async function (val) {
                    if (this.watch && val) {
                        console.log("watched");
                        this.division = [];
                        let url = "Division/DDLDivisionList";
                        const divisionBranch = await axios.get(url, {
                            params: {ProvinceID: val}
                        });
                        this.division = divisionBranch.data.divisionList;

                        const { data } = await axios.get("District/GetDistrictListByProvince/" + val);
                        this.Districts = data
                    }
                },
                deep: true
            }
        },
        components: {
            createBranchForm,
            editBranchForm,
            deleteListData,
            DocumentViewer,
            BranchServices,
            BranchDetails,
        },
        created() {
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: {
                    snackbar: false,
                    modal: false,
                    branchServices: false,
                    branchDetails: false
                },
                dialogDocument: false,
                documents: {},
                countBranch: {
                    InActive: 0,
                    Active: 0
                },
                panel: [0],
                pendingData: {},
                watch: false,
                success: false,
                pendingDataFlag: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                popout: true,
                isLoading: false,
                deleteFormData: {},
                branchData: {},
                rows: [],
                DivisionList: [],
                DistrictList: [],
                ProvinceList: [],
                Districts: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "isActive",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                filterEditedData: {
                    divisionId: "",
                    provinceId: "",
                    districtName: "",
                    branchAddress: "",
                    branchCode: "",
                    branchName: "",
                    branchPhoneNumber: ""
                },
                pendingDataHeader: [
                    {label:  this.$t('form.sector_name'), field: "divisionName"},
                    {label:  this.$t('table.brand_name'), field: "branchName"},
                    {label:  this.$t('table.is_approved'), field: "isApproved"},
                    {label:  this.$t('table.actions'), field: "action"}
                ],
                isloadingFromServer:false
            };
        },

        mounted() {
            this.loadItems();
            if (this.isSuperAdmin || this.isMinistry || this.isAssociation || this.isParentDivision) {
                this.filterData();
                this.getProvinceList();
            }
            if (this.isProvince) {
                this.getDivisionList()
            }

        },
        destroyed() {
        },

        methods: {
            async getProvinceList() {
                const ProvinceList = await axios.get('Province/DDLProvinceList')
                this.ProvinceList = ProvinceList.data
                console.log('province list: ', this.ProvinceList)
            },

            async getDivisionList(id) {
                if (id) {
                    let {data} = await axios.get('Division/DDLDivisionList?ProvinceID=' + id)
                    this.DivisionList = data
                } else {
                    let {data} = await axios.get('Division/DDLDivisionList')
                    this.DivisionList = data
                }

            },
            async getDistrictList(id) {
                let { data } = await axios.get('District/GetDistrictListByProvince/' + id)
                console.log("district: ", this.data)
                this.DistrictList = data

            },
            //called form child components
            showDocument(data) {
                this.dialogDocument = true
                this.documents = data
            },
            onDocumentClose() {
                this.dialogDocument = false;
            },
            activeMethod(data, status) {
                console.log(data, status);
                this.isloadingFromServer = true;
                axios
                    .post("Branch/ApproveBranch", {
                        branchID: data.branchID,
                        userID: data.userID,
                        isApproved: status
                    })
                    .then(() => {
                        this.isloadingFromServer = false;
                        this.snackbar.snackbar = true
                        this.callbackResponse.message= "Pending branch status change"
                        this.pendingDataFlag = false;
                        this.pendingMethods();
                        this.getTotalCounts();
                    }).catch(err => {
                         this.isloadingFromServer = false;
                    console.log(err)
                });
            },
            async pendingMethods() {
                // console.log(this.countBranch);
                let pendingBranch = await axios.get("Branch/GetPendingBranchRequest");
                console.log(pendingBranch);
                if (pendingBranch) {
                    this.pendingDataFlag = true;
                    this.pendingData = pendingBranch.data;
                }
                console.log(this.pendingData);
            },

            onResponse(data) {
                if (data) {
                    this.snackbar.snackbar = !!data.message
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false;
                    this.callbackResponse = data;
                    this.loadItems();
                }
            },

            async filterData() {
                const provinceRequest = await axios.get("Province/DDLProvinceList");
                this.province = provinceRequest.data.data;
            },

            editItem(props) {
                console.log(props);
                this.filterEditedData.branchId = props.branchID;
                this.dialogEdit = true;
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Branch/DeleteBranch/" + props.branchID;
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
                this.getProvinceList();
            },
            clearInput() {
                this.serverParams.DivisionID = null
                this.serverParams.ProvinceID = null
                this.serverParams.DistrictName = null
                this.serverParams.BranchName = null
                this.loadItems();
                this.getProvinceList();
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            // load items is what brings back the rows from server
            getTotalCounts() {
                console.log("first")
                axios.get("Branch/GetTotalCounts").then(response => {
                    this.countBranch.Active = response.data.active;
                    this.countBranch.InActive = response.data.inActive;
                }).catch(err => {
                    console.log(err)
                });
            },
            //getProvinceList() {
            //    debugger
            //    const ProvinceList =  axios.get('Province/DDLProvinceList')
            //    this.ProvinceList = ProvinceList.data
            //    console.log('province list: ', this.ProvinceList)
            //},
            loadItems() {
                // eslint-disable-next-line no-console
                this.isLoading = true
                let param = {
                    IsFromDivision: false,
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    DivisionID: this.serverParams.DivisionID,
                    ProvinceID: this.serverParams.ProvinceID,
                    District: this.serverParams.DistrictName,
                    BranchName: this.serverParams.BranchName
                };
                /* try {
                     const response = await axios.post("Branch/BranchList", param);
                     this.getTotalCounts();
                     this.rows = response.data.data;
                     this.totalRecords = response.data.totalCount;
                 } catch (e) {
                     if (e.response.data.State == "AccessTokenExpired") {
                         this.apiResponse(e);
                         this.loadItems();
                     }
                 }*/

                axios.post("Branch/BranchList", param).then((response) => {
                    this.getTotalCounts();
                    this.getProvinceList();
                    console.log('response', response)
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                    this.isLoading = false
                }).catch((e) => {
                    console.log(e)
                    this.isLoading = false
                })
            },
            dialogueClose() {
                this.snackbar.modal = false
                this.snackbar.branchServices = false
                this.snackbar.branchDetails = false
            },
            loadDetails({divisionName, branchID}) {
                this.snackbar.modal = true
                this.snackbar.branchDetails = true
                this.branchData.divisionName = divisionName
                this.branchData.branchID = branchID
                console.log(this.branchData.divisionName, this.branchData.branchID)
            },
            loadServices({divisionName, branchID}) {
                this.snackbar.modal = true
                this.snackbar.branchServices = true
                this.branchData.divisionName = divisionName
                this.branchData.branchID = branchID
                console.log(this.branchData.divisionName, this.branchData.branchID)
            },
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    .line-numbers {
        background: #1976d2 !important;
    }
</style>
