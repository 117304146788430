<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('table.edit_brand')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar> 
        <v-card-text>
            <v-container>
                <form>
                    <v-select
                            v-if="provShow"
                            v-model="editedData.provinceId"
                            :items="province"
                              dense
                                                outlined
                            :label="$t('form.select_province')"
                            item-value="provinceID"
                            item-text="provinceName"
                    ></v-select>
                    <v-select
                            v-if="divShow"
                            v-model="editedData.divisionId"
                            :items="division"
                              dense
                                                outlined
                            :label="$t('form.select_division')"
                            item-value="divisionID"
                            item-text="divisionName"
                    ></v-select>
                    <v-text-field
                            v-model="editedData.branchName"
                            :error-messages="branchNameErrors"
                            :label="$t('table.brand_name')"
                            required
                                  dense
                                                outlined
                            @input="$v.editedData.branchName.$touch()"
                            @blur="$v.editedData.branchName.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.branchCode"
                            :error-messages="branchCodeErrors"
                            :label="$t('table.brand_code')"
                            required
                                  dense
                                                outlined
                            @input="$v.editedData.branchCode.$touch()"
                            @blur="$v.editedData.branchCode.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.branchAddress"
                            :error-messages="branchAddressErrors"
                            :label="$t('table.brand_address')"
                            required
                                  dense
                                                outlined
                            @input="$v.editedData.branchAddress.$touch()"
                            @blur="$v.editedData.branchAddress.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.branchPhoneNumber"
                            :error-messages="branchPhoneNumberErrors"
                            :label="$t('table.brand_phone')"
                            required
                                  dense
                                                outlined
                            @input="$v.editedData.branchPhoneNumber.$touch()"
                            @blur="$v.editedData.branchPhoneNumber.$touch()"
                    ></v-text-field>
                    <v-select
                            v-model="editedData.status"
                            :items="statusList"
                              dense
                                                outlined
                            :label="$t('form.select_status')"
                            item-value="value"
                            item-text="name"
                    ></v-select>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('form.cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('form.save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editBranchForm",
        props: ["success", "branchId"],
        computed: {
            branchAddressErrors() {
                const errors = [];
                if (!this.$v.editedData.branchAddress.$dirty) return errors;
                !this.$v.editedData.branchAddress.required &&
                errors.push("Ministry id is required");
                return errors;
            },
            branchNameErrors() {
                const errors = [];
                if (!this.$v.editedData.branchName.$dirty) return errors;
                !this.$v.editedData.branchName.required &&
                errors.push("Brand name is required.");
                return errors;
            },
            branchCodeErrors() {
                const errors = [];
                if (!this.$v.editedData.branchCode.$dirty) return errors;
                !this.$v.editedData.branchCode.required &&
                errors.push("Brand Code is required.");
                return errors;
            },
            branchPhoneNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.branchCode.$dirty) return errors;
                !this.$v.editedData.branchCode.required &&
                errors.push("Brand Code is required.");
                return errors;
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            created: {
                handler: function (val) {
                    console.log(val);
                    this.$emit("formResponse", val);
                },
                deep: true
            },
            "editedData.provinceId": {
                handler: async function (val) {
                    if (val) {
                        this.division = [];
                        let url = "Division/GetDivisionListByProvinceId/" + val;
                        const divisionBranch = await axios.get(url);
                        this.division = divisionBranch.data.divisionList;
                        // this.editedData.divisionId = val
                        if (this.first) {
                            console.log(this.editedData.divisionId);
                            this.first = false;
                        }
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                first: true,
                created: false,
                provShow: false,
                divShow: false,
                province: [],
                division: [],
                statusList: [{name: "Approved", value: "Approved"}, {name: "Rejected", value: "Rejected"}],
                editedData: {
                    divisionId: "",
                    provinceId: "",
                    branchAddress: "",
                    branchCode: "",
                    branchName: "",
                    branchPhoneNumber: ""
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                branchAddress: {required},
                branchPhoneNumber: {required},
                branchCode: {required},
                branchName: {required}
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                let permissionData = JSON.parse(localStorage.getItem("tokenData"));
                if (permissionData.role == "Ministry") {
                    this.provShow = true;
                    this.divShow = true;
                    const provinceRequest = await axios.post("Province/ProvinceList", {});
                    this.province = provinceRequest.data.data;
                } else if (permissionData.role == "Province") {
                    this.divShow = true;
                    this.editedData.provinceId = permissionData.ProvinceID;
                }
                await axios
                    .get("Branch/GetBranchByID", {params: {branchID: this.branchId}})
                    .then(response => {
                        this.editedData.provinceId = response.data.provinceID;
                        this.editedData.divisionId = response.data.divisionID;
                        this.editedData.branchId = response.data.branchID;
                        this.editedData.branchAddress = response.data.branchAddress;
                        this.editedData.branchCode = response.data.branchCode;
                        this.editedData.branchName = response.data.branchName;
                        this.editedData.branchPhoneNumber = response.data.branchPhoneNumber;
                        this.editedData.status = response.data.status;
                        // this.editedData.isActive = response.data.isActive;
                    });
                // console.log(this.editedData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        BranchID: this.editedData.branchId,
                        DivisionID: this.editedData.divisionId,
                        BranchName: this.editedData.branchName,
                        BranchCode: this.editedData.branchCode,
                        BranchAddress: this.editedData.branchAddress,
                        BranchPhoneNumber: this.editedData.branchPhoneNumber,
                        Status: this.editedData.status
                    };
                    axios.post("Branch/UpdateBranch", param).then(response => {
                        this.editedData = {};
                        this.servicesData.data = response.data
                        this.servicesData.message = "Package added Succcessfully"
                        this.servicesData.color = "success"
                        this.dialogueClose()
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            }
        }
    };
</script>

<style scoped>
</style>